import BaseService from "@core/services/baseService";

export default class RiskService extends BaseService{
    getRiskQuestions(...args){
        var params = {'system_form_id': args[0].system_form_id};
        if (args[0].client_id != 'undefined') {
            params.client_id = args[0].client_id;
        }
        return this.axiosIns.get(this.formConfig.riskQuestionEndpoint, {params: params})
    }
    recalculateRisk(answers){
        let risk = 0;
        if (answers !== undefined) {
            answers.forEach(function (single) {
                risk += single.riskFactor;
            });
        }

        return risk;
    }
}