<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="owner-create-wrapper"
  >

    <b-row>
      <b-col
          cols="4" class="mt-50">
        <h2>Risk: {{ ownerRisk }}
        </h2>
      </b-col>
      <b-col
          cols="4" class="mt-50 text-center">
        <h2 v-if="owner.completed"><feather-icon icon="CheckIcon" size="28" class="text-success" /> Complete</h2>
        <h2 v-if="!owner.completed"><feather-icon icon="AlertTriangleIcon" size="28" class="text-warning" /> Incomplete</h2>
      </b-col>
      <b-col
          cols="4"
          class="mt-50 text-right"
      >
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :href="'/owner/'+owner.id+'/edit'"
        >
          Edit
        </b-button>
      </b-col>
    </b-row>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <p><strong>Name:</strong> {{ owner.firstName }} {{ owner.lastName }}</p>
        </b-col>
        <b-col md="6">
          <p><strong>Boom #:</strong> {{ owner.boomNumber }}</p>
        </b-col>
        <b-col md="6">
          <p><strong>Email:</strong> {{ owner.email }}</p>
        </b-col>
        <b-col md="6">
          <p><strong>Title:</strong> {{ owner.title }}</p>
        </b-col>
        <b-col md="12">
          <strong>Relevant attributes for this individual:</strong>
          <ul>
            <li v-for="attribute in owner.attributes">{{ attribute }}</li>
          </ul>
        </b-col>
        <b-col md="6">
          <strong>Active in public practice?</strong>
          {{ owner.activeInPublicPractice }}
        </b-col>
        <b-col md="6">
          <strong>BO approved by compliance team?</strong>
          {{ owner.complianceApprovedBO }}
        </b-col>
        <b-col md="6">
          <strong>% holding of this Individual: </strong> {{ owner.holdingPercent }}%
        </b-col>
        <b-col md="12">
          <strong>Professional memberships:</strong>
          <ul>
            <li v-for="membership in owner.memberships">{{ membership }}</li>
          </ul>
        </b-col>
        <b-col md="12" class="mt-3">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Question</th>
              <th class="text-center">Answer</th>
              <th class="text-center">File</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(riskQuestion,index) in riskQuestions" :key="index">
              <td>{{ riskQuestion.question }}</td>
              <td class="text-center">
                <div v-if="form[riskQuestion.questionLabel]">
                  {{ form[riskQuestion.questionLabel].answer }}
                </div>
              </td>
              <td class="text-center">
                <div v-if="form[riskQuestion.questionLabel]">
                  {{ form[riskQuestion.questionLabel].file }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import ownerService from "@core/services/owner/useOwner";
import {$themeConfig} from "@themeConfig";
import riskService from "@core/services/risk/useRisk";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BOverlay,
    VBTooltip,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.getRiskQuestions();
  },
  data() {
    return {
      owner: {
        id: 0,
        practiceId: 0,
        firstName: "",
        lastName: "",
        email: "",
        title: "",
        attributes: [],
        memberships: [],
        activeInPublicPractice: "",
        holdingPercent: 0,
        boomNumber: "",
        completed: false,
      },
      form: {},
      riskQuestionCategories: [],
      riskQuestions: [],
      ownerRisk: 0,
      pageLoaded: false
    }
  },
  methods: {
    getOwner() {
      if ( this.$route.params.id ){
        ownerService.getOwner({
          id: this.$route.params.id
        }).then(response => {
          this.owner = response.data;
          let that = this;
          if ( this.owner.answers !== undefined && this.owner.answers.length > 0 ){
            this.owner.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }

          this.recalculateRisk();
          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': 5
      }).then(response => {
        let that = this;
        this.riskQuestions = response.data.questions;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions.forEach(function (question) {
          that.$set(that.form, question.questionLabel, {input: ""})
        });

        this.getOwner();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });

    },
    recalculateRisk() {
      this.ownerRisk = riskService.recalculateRisk(this.owner.answers);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
